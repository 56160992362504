<template>
  <div class="products">
    <div class="page-banner">
      <div class="container">
        <div class="wrapper" style="background-image:url('ERP-228x228.jpg');">
          <div class="content">
            <h1>
              <a class="title">
                <span class="code">Manufacturing </span>
                <br />TBS ERP System
              </a>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="container product-info">
      <div class="row">
        <div class="col-xs-12">
          <!-- <b-tabs content-class="mt-3">
            <b-tab title="Overview" active><p>I'm the first tab</p></b-tab>
            <b-tab title="Images"><p>I'm the second tab</p></b-tab>
            <b-tab title="Downloads"><p>I'm a disabled tab!</p></b-tab>
            <b-tab title="Spec"><p>I'm a disabled tab!</p></b-tab>
          </b-tabs> -->
          <!-- <ul class="nav nav-tabs">
            <li class="active">
              <a href="#overview" data-toggle="tab">Overview</a>
            </li>
            <li>
              <a href="#variant-306" data-toggle="tab">Contact Interface</a>
            </li>
          </ul> -->
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-9">
          <div class="tab-content">
            <div class="tab-pane" id="overview">
              <p>
                The ACOS3 Microprocessor Card is designed to support various
                applications such as loyalty program, parking registration,
                network access control, public transportation,
                telecommunication, and electronic purse.
              </p>
              <p>
                ACOS3 is specifically designed for secure payment applications
                as well as loyalty programs, logical access control and e-Purse
                applications. Likewise, it may be utilized for closed-loop
                payment solutions. ACS also offers various card customization
                options such as inclusion of magnetic stripe, signature panel
                and others.
              </p>
              <p>
                The ACOS3 is available in contact, combi and contactless
                interface.
              </p>
            </div>
            <div class="tab-pane" id="variant-306">
              efewf
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import products from "@/assets/json/product.json";

export default {
  name: "Product",
  data() {
    return {
      product: []
    };
  },
  mounted() {
    var self = this;
    self.product = products;
    // self.product = self._.find(products, { title: self.$route.params.id });
  }
};
</script>
